<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <!-- <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
        <el-form-item label="公司名称" prop="companyName">
          <el-input
            v-model="formData.companyName"
            placeholder="请输入公司名称"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="账号管理员" prop="managerName">
          <el-input
            v-model="formData.managerName"
            placeholder="请输入账号管理员名称"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="会员主账号" prop="account">
          <el-input
            v-model="formData.account"
            placeholder="请输入会员主账号"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司简介" prop="companyAbbreviation">
          <el-input
            v-model="formData.companyAbbreviation"
            placeholder="请输入公司简介"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司logo" prop="companyLogoId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z2.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="公司联系电话" prop="tel">
          <el-input
            v-model="formData.tel"
            placeholder="请输入公司联系电话"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="网站地址" prop="websiteAddress">
          <el-input
            v-model="formData.websiteAddress"
            placeholder="请输入网站地址"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="formData.email"
            placeholder="请输入邮箱"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司地址" prop="companyAddress">
          <el-input
            v-model="formData.companyAddress"
            placeholder="请输入公司地址"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
          <el-input
            v-model="formData.unifiedSocialCreditCode"
            placeholder="请输入统一社会信用代码"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司经营状态" prop="companyStatus">
          <el-input
            v-model="formData.companyStatus"
            placeholder="请输入公司经营状态"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司法人" prop="legalPerson">
          <el-input
            v-model="formData.legalPerson"
            placeholder="请输入公司法人"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司类型" prop="typeId">
          <el-select
            v-model="formData.typeId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建日期" prop="companyCreateDate">
          <el-date-picker
            v-model="formData.companyCreateDate"
            @change="change123"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择创建时间"
          />
        </el-form-item>
        <el-form-item label="注册地址" prop="registeredAddress">
          <el-input
            v-model="formData.registeredAddress"
            placeholder="请输入注册地址"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="注册资本" prop="registeredCapital">
          <el-input
            v-model="formData.registeredCapital"
            placeholder="请输入注册资本"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="经营范围" prop="businessScope">
          <el-input
            v-model="formData.businessScope"
            placeholder="请输入经营范围"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="公司营业类型" prop="businessType">
          <el-input
            v-model="formData.businessType"
            placeholder="请输入公司营业类型"
            style="width: 50%"
          />
        </el-form-item>
        <el-form-item label="到期时间" prop="expireDateMsg">
          <el-date-picker
            v-model="formData.expireDateMsg"
            @change="change456"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择到期时间"
          />
        </el-form-item>

        <el-form-item label="公司简介" prop="companyProfile">
          <div style="line-height: normal">
            <uEditor v-model="formData.companyProfile"></uEditor>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.companyId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog> -->
<el-dialog title="添加会员分类" :visible="dialog" @close="btnCancel1">
      <!-- 表单 -->
      <el-form
        ref="pForm"
        :model="fdata"
        :rules="rules1"
        label-width="200px">
        <el-form-item label="标题名称" prop="typeName">
          <el-input
            v-model="fdata.typeName"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
        
      </el-row>
      
    </el-dialog>
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 60%">
            <el-select
              v-model="typeId"
              placeholder="请选择公司分类"
              @change="selectChange2"
              style="wdith: 300px; margin-right: 10px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchcompanyName"
              placeholder="请输入公司名称"
              style="width: 50%"
            ></el-input>
            <el-button
              type="primary"
              slot="append"
              @click="searchcompanyList"
              style="margin-left: 5px"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="12" align="right">
           <el-button class="el-icon-edit" type="warning" @click="addfl"
            >管理会员分类</el-button
          >
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加会员</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
         :row-class-name="rowClass"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="companyLogoId"
          label="公司logo"
          width="200"
        >
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.companyLogoId "
                :preview-src-list="[
                  'http://img.c-fol.cn/' + scope.row.companyLogoId,
                ]"
              >
              <div slot="error" class="image-slot">
             <img src="../../assets/暂无.jpg"   style="width: 100%" alt="">
      </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="companyName"
          label="公司名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="managerName"
          label="管理员名称"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          align="center"
          prop="account"
          label="管理员账号"
          :show-overflow-tooltip="true"
        />
<!--               
        <el-table-column
          align="center"
          prop="typeArr"
          label="公司类型"
         
           :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column
          align="center"
          prop="businessScope"
          label="经营范围"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column
          align="center"
          prop="companyProfile"
          label="公司简介"
           :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="accountLimitNum"
          label="账号限制数量"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        />

          <el-table-column
          align="center"
          prop="serviceName"
          label="已选服务"
          :show-overflow-tooltip="true"
        />





           <el-table-column
          align="center"
          prop="payTime"
          label="缴费时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.payTime | formeDate }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | formeDate }}
          </template>
        </el-table-column> -->
         <el-table-column
          align="center"
          prop="expireTime"
          label="会员过期时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.expireTime | formeDate }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="jump(scope.row)">
              会员账号
            </el-button>
            <el-button type="text" size="small" @click="run(scope.row)">
              会员名片
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="setCompanyInfoStatus(scope.row)"
            >
              {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>

            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delCompanyInfo(scope.row)"
            >
              删除
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="renewCompany(scope.row)"
            >
              会员续费
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getAllCompanyList"
      />
    </div>
  </div>
</template>

<script>
import {
  renewCompany,
   addType,
  getUploadToken,
  getTypes,
  getAllCompanyList,
  addCompanyInfo,
  setCompanyInfoStatus,
  modifyCompanyInfo,
  delCompanyInfo,
} from "../../request/http";

export default {
  data() {
    return {
        dialog: false,
        url:'../../assets/暂无.jpg',
      fdata: {
        typeName: "",
        typeGroup: "COMPANY",
      },
      editOradd: 1, // 用于判断是添加还是修改 0、添加 1、修改
      typeId: "",
      imageUrl: "",
      totalData: [], //统计信息
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: "", // 公司列表
      postData: {
        token: "",
        key: "", //上传的Logo
      },
      options: [],
      total: 0, //总条数
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formData: {
        sort: "",
        businessType:"",
        companyName: "",
        managerName: "",
        account: "",
        companyLogoId: "",
        tel: "",
        companyAddress: "",
        companyStatus: "",
        typeId: null,
        businessScope: "",
        email: "",
        companyProfile: "",
        expireDateMsg: "",
        companyAbbreviation: "",
        websiteAddress: "",
        unifiedSocialCreditCode: "",
        legalPerson: "",
        companyCreateDate: "",
        registeredAddress: "",
        registeredCapital: "",
      },
      searchcompanyName: "", //搜索名称
      rules: {
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
        ],
        managerName: [
          { required: true, message: "账号管理员不能为空", trigger: "blur" },
        ],
        account: [
          { required: true, message: "会员主账号不能为空", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
        companyLogoId: [
          { required: true, message: "公司logo不能为空", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "公司联系电话不能为空", trigger: "blur" },
        ],
        companyAddress: [
          { required: true, message: "公司地址不能为空", trigger: "blur" },
        ],
        companyStatus: [
          { required: true, message: "公司经营状态不能为空", trigger: "blur" },
        ],
        typeId: [
          { required: true, message: "公司类型不能为空", trigger: "blur" },
        ],
        businessScope: [
          { required: true, message: "经营范围不能为空", trigger: "blur" },
        ],
        companyProfile: [
          { required: true, message: "公司简介不能为空", trigger: "blur" },
        ],
        expireDateMsg: [
          { required: true, message: "到期时间不能为空", trigger: "blur" },
        ],
      },
      rules1: {
        typeName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getAllCompanyList();
    this.getList();
  },
  methods: {
   async renewCompany(row){
 try {
        await this.$confirm("确定要续费吗");
        console.log(row);
        await renewCompany({
          companyId: row.companyId,
        });
        this.$message.success("已续费");
      } catch (error) {
        console.log(error);
      }
      this.getAllCompanyList();
    },
     addfl(){
         this.$router.push({
        path: "/managementvip",
       
      });
     },
     btnOK1() {
      this.$refs.pForm
        .validate()
        .then(() => {
            return addType(this.fdata);
        })
        .then(() => {
          //  提示消息
            this.$message.success("添加分类成功");
            this.getList();
          this.dialog = false;
        });
    },
 btnCancel1() {
      this.fdata = {};
      this.$refs.pForm.resetFields();
      this.dialog =false
    },
    //获取分类列表
    async getList() {
      let res = await getTypes({
        typeGroup: "COMPANY",
      });
      console.log(res);

      this.options = res.data;
    },
      //通过分类Id 获取公司列表
    async selectChange2(val) {
      this.queryParams.typeId = val;
      let { data, count } = await getAllCompanyList(this.queryParams);
      this.list = data;
      this.total = count;
    },
    //选择公司分类
    selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    //选择公司创建时间
    change123(val) {
      this.formData.companyCreateDate = val;
      console.log(this.formData.companyCreateDate);
    },
    //选择会员到期时间
    change456(val) {
      this.formData.expireDateMsg = val;
      console.log(this.formData.expireDateMsg);
    },
    //设置公司上下架
    async setCompanyInfoStatus(row) {
      let id = row.companyId;
      let res = await setCompanyInfoStatus({ companyId: id });
      console.log(res.code);
      if(res.code == 0 ){
           this.$message({
          type: "success",
          message: "设置成功",
        });
      } else{
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
       
      this.getAllCompanyList();
    },
    //获取公司列表
    async getAllCompanyList() {
      let { data, count } = await getAllCompanyList(this.queryParams);
      this.list = data;
      this.total = count;
      console.log(this.list);
      this.loading = false;
    },
    //查询公司
    async searchcompanyList() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        companyName: this.searchcompanyName,
      };

      let { data, count } = await getAllCompanyList(this.queryParams);
      this.list = data;
      this.total = count;
    },
    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.companyLogoId = res.key;
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
    // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null
        this.editOradd = 0;
      let editOradd = this.editOradd;
      this.$router.push({
        path: "/addEditClass",
        query: { editOradd },
      });
      // this.title = "添加公司信息";
      // this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
        this.editOradd = 1;
      let editOradd = this.editOradd;
      let obj = val;
      this.$router.push({
        path: "/addEditClass",
        query: { editOradd, obj },
      });
       
      // console.log(val);
      // this.title = "编辑公司信息";
      // this.imageUrl = "http://img.c-fol.cn/" + val.companyLogoId;
      // this.formData = {
      //   sort: val.sort,
      //   companyId: val.companyId,
      //   companyName: val.companyName,
      //   managerName: val.managerName,
      //   account: val.account,
      //   tel: val.tel,
      //   businessType:val.businessType,
      //   companyLogoId: val.companyLogoId,
      //   companyAddress: val.companyAddress,
      //   companyStatus: val.companyStatus,
      //   typeId: val.typeId,
      //   businessScope: val.businessScope,
      //   email: val.email,
      //   companyProfile: val.companyProfile,
      //   expireDateMsg: this.getLocalTime(val.expireTime),
      //   companyAbbreviation: val.companyAbbreviation,
      //   websiteAddress: val.websiteAddress,
      //   unifiedSocialCreditCode: val.unifiedSocialCreditCode,
      //   legalPerson: val.legalPerson,
      //   companyCreateDate: val.companyCreateDate,
      //   registeredAddress: val.registeredAddress,
      //   registeredCapital: val.registeredCapital,
      // };
      // this.showDialog = true;
    },

    // 确认
    // btnOK() {
    //   this.$refs.perForm
    //     .validate()
    //     .then(() => {
    //       if (this.formData.companyId) {
    //         return modifyCompanyInfo(this.formData);
    //       } else {
    //         return addCompanyInfo(this.formData);
    //       }
    //     })
    //     .then(() => {
    //       //  提示消息
    //       if (this.formData.companyId) {
    //         this.$message.success("编辑成功");
    //       } else {
    //         this.$message.success("新增成功");
    //       }

    //       this.getAllCompanyList();

    //       this.showDialog = false;
    //     });
    // },

    //弹窗取消函数
    btnCancel() {
      this.imageUrl = "";
      this.formData = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除公司信息
    async delCompanyInfo(row) {
      try {
        await this.$confirm("确定要删除该公司吗");
        await delCompanyInfo({ companyId: row.companyId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getAllCompanyList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
//格式化日期

    getLocalTime(nS) {
      let date = new Date(nS);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + "-" + month + "-" + day;
      // 2018-10-09
      return date;
    },
    //跳转
    jump(val) {
      let companyId = val.companyId;
      this.$router.push({
        path: "/Memberaccount",
        query: { companyId },
      });
    },
    run(val) {
      let companyId = val.companyId;
      this.$router.push({
        path: "/companyCards",
        query: { companyId },
      });
    },
      //处理表单
  //每一行的结束时间减去现在的时间 如果小于等于30天 就给满足条件的行添加一个类名,高亮显示
     rowClass({row, rowIndex}) {
 let date1 = getSystemDate() // 现在
  let date2 =row.expireTime  //结束
  let a =new Date(date2).getTime()  //结束
  let b =new Date(date1).getTime()   //现在

      if (a<=b) {
       return " error_class"
      } 
    
          
    
},
  },
};
//获取现在的时间
function getSystemDate(){
 
    var systemDate = new Date();
 
    // 获取当年
    var year = systemDate.getFullYear();
 
    // 获取当月 （月+1是因为js中月份是按0开始的）
    var month = systemDate.getMonth() + 1;
 
    // 获取当日
    var day =  systemDate.getDate();

    //获取小时
    var h = systemDate.getHours()

    //获取分钟
    var m = systemDate.getMinutes()

    //获取秒
    var s =systemDate.getSeconds()
 
    if (day < 10) { // 如果日小于10，前面拼接0
 
        day = '0' + day;
    }
 
    if (month < 10) { // 如果月小于10，前面拼接0
 
        month = '0' + month;
    }
    if(h<10){
      h ='0'+h
    }
     if(m<10){
      m ='0'+m
    }
     if(s<10){
      s ='0'+s
    }
 
    return `${year}-${month}-${day} ${h}:${m}:${s}`
 
}
</script>

<style>
.el-table .error_class {
    color: red;
}
</style>